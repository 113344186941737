<template>
  <b-card>
    <b-card-header class="card-header card-header-sans">
      <b-card-title>
        Список категорий
      </b-card-title>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        @click="handleAddCategory"
      >
        <feather-icon
          icon="PlusCircleIcon"
          class="mr-50"
        />
        Добавить категорию
      </b-button>
    </b-card-header>
    <b-overlay
      :show="[PAGE_STATUS.LOADING].includes(pageStatus)"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-table
        class="categories-table"
        responsive
        :sticky-header="false"
        :no-border-collapse="true"
        :items="items"
        :fields="fields"
      >
        <template #head(id)>
          <div class="text-nowrap">
            ID
          </div>
        </template>

        <template #head()="scope">
          <div class="text-nowrap">
            {{ scope.label }}
          </div>
        </template>

        <template #cell()="data">
          <span class="text-nowrap">{{ data.value }}</span>
        </template>

        <template #cell(actions)="data">
          <div class="category-actions">
            <b-button
              class="mr-50"
              v-ripple="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="handleEditCategory(data.item)"
            >
              <feather-icon icon="Edit2Icon"/>
            </b-button>
            <b-button
              v-ripple="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="handleDeleteCategory(data.item.id)"
            >
              <feather-icon icon="Trash2Icon"/>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BTable, BAvatar, BBadge, BButton, BModal, VBModal, BOverlay } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import { getApiErrMsg } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const PAGE_STATUS = {
  INITIAL: 1,
  LOADING: 2,
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BModal,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      PAGE_STATUS,
      pageStatus: PAGE_STATUS.INITIAL,
      fields: [
        {
          key: 'id',
          variant: 'primary',
          class: 'categories-table-cell-id',
          isRowHeader: true,
        },
        {
          key: 'name',
          label: 'Название',
          class: 'categories-table-cell-name',
        },
        {
          key: 'position',
          label: 'Позиция',
          class: 'categories-table-cell-position',
        },
        {
          key: 'created_at',
          label: 'Дата создания',
          class: 'categories-table-cell-created-at',
          formatter: value => new Date(value).toLocaleString('uz-UZ', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            hour12: false,
            minute:'2-digit'
          }),
        },
        {
          key: 'actions',
          class: 'categories-table-cell-actions',
          label: 'Действия',
          stickyColumn: true,
        }
      ],
      items: [],
    }
  },
  methods: {
    async fetchCategories() {
      this.pageStatus = PAGE_STATUS.LOADING

      try {
        const res = await useJwt.getCategories()

        this.pageStatus = PAGE_STATUS.INITIAL

        this.items = res.data
      } catch(err) {
        this.pageStatus = PAGE_STATUS.INITIAL
      }
    },
    handleDeleteCategory(id) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить категорию?', {
          title: 'Удаление категории',
          size: 'sm',
          okTitle: 'Удалить',
          okVariant: 'danger',
          centered: true,
          cancelTitle: 'Отменить'
        })
        .then(value => {
          if (!value) {
            return
          }

          this.deleteCategory(id)
        })
    },
    handleAddCategory() {
      this.$router.push({ name: 'categoryAdd' })
    },
    handleEditCategory(item) {
      this.$router.push({ name: 'categoryEdit', params: { id: item.id } })
    },
    async deleteCategory(id) {
      try {
        await useJwt.deleteCategory(id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Категория удалена.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })

        this.items = this.items.filter((item) => item.id !== id)
      } catch(err) {
        const msg = getApiErrMsg(err, 'Не удалось удалить категорию, попробуйте еще раз.')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  created() {
    this.fetchCategories()
  }
}
</script>

<style>
.card .card-header-sans {
  border-radius: 0;
  margin-bottom: 1.53rem;
  padding: 0;
}

.category-actions {
  display: flex;
  align-items: center;
}

th.categories-table-cell-name {
  width: 90%;
}

td.categories-table-cell-position {
  text-align: center;
}

.categories-table-cell-actions.b-table-sticky-column {
  left: auto !important;
  right: 0 !important;
}
</style>
